<template>
    <div>
        <CoreContainer>
            <BaseContainerContent
                :padding="{ top: 'none', bottom: 'large' }"
            >
                <!-- TODO - pagination handler -->
                <BlogArticleList :active-category="articleCategoryResponse?.getItem()"
                                 :article-categories="articleCategoriesResponse?.getItems() ?? []"
                                 :articles="articlesResponse?.getItems() ?? []"
                />

                <div class="mt-18 flex flex-col items-center justify-center gap-6 sm:flex-row sm:justify-between">
                    <UiPagination
                        :aria-label="$t('accessibility.pagination_for_articles')"
                        :meta="articlesResponse?.getMeta()"
                        @change="pagination.page = $event"
                    />

                    <UiButton
                        v-if="!articlesResponse?.isLastPage()"
                        color="primary"
                        size="large"
                        @click="pagination.page++"
                    >
                        <template #leading>
                            <IconArrowRight rotate="right" class="sim-c-simplo" />
                        </template>
                        {{ $t('pages.blog.show_more', (articlesResponse?.getMeta()?.per_page ?? 0)) }}
                    </UiButton>
                </div>
            </BaseContainerContent>
        </CoreContainer>

        <!--  NEWSLETTER SECTION  -->
        <SectionNewsletter />
    </div>
</template>

<script lang="ts" setup>
const { page } = useCurrentPage()

const { pagination } = usePagination({
    perPage: 8,
})

const [
    { data: articleCategoryResponse },
    { data: articleCategoriesResponse },
    { data: articlesResponse },
] = await Promise.all([
    useArticleCategoriesApiService()
        .forId(page.value!.model!.id)
        .useGet(),
    useArticleCategoriesApiService()
        .embed([
            ArticleCategoryModel.EMBED_URLS,
        ])
        .whereEquals(ArticleCategoryModel.ATTR_PARENT_ID, page.value!.model!.id)
        .useGet(),
    useArticlesApiService()
        .embed([
            ArticleModel.EMBED_URLS,
            ArticleModel.EMBED_USER,
            ArticleModel.EMBED_IMAGE_URL,
            ArticleModel.EMBED_DEFAULT_ARTICLE_CATEGORY,
            ArticleModel.EMBED_TAGS,
        ])
        .whereEquals(ArticleModel.FILTER_ARTICLE_CATEGORY_IDS, page.value!.model!.id)
        .sortByDesc(ArticleModel.ATTR_PUBLISH_AT)
        .useGet(),
])

</script>
